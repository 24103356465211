export { PrismaClient, Prisma as PrismaTypes } from "./prismaGenerated_DO_NOT_TOUCH";

import {
  BrowserVideoClientStateSession,
  Discount,
  ExternalIntegration,
  File,
  GatherEvent,
  GatherEventMessage,
  ObjectTemplate,
  ObjectVariant,
  ObjectVariantSpritesheet,
  ObjectVariantSpritesheetAnimation,
  Prisma,
  Recording,
  Reservation,
  Sound,
  Space,
  SpaceCapacityWarning,
  SpaceCapacityWarningUser,
  SpaceExtension,
  SpaceItem,
  SpaceSSOSettings,
  SpaceStripeCustomer,
  SpaceTemplate,
  SpaceTrial,
  SpaceUser,
  SpaceUserDesk,
  SpaceUserOnboarding,
  SpaceUserOnboardingChecklist,
  SpaceUserSetting,
  SpawnToken,
  User,
  UserDeletionForStaging,
  UserGatherEvent,
  UserOutfit,
  UserOutfitTemplate,
  UserSpeaker,
  Wearable,
  WearablePart,
} from "./prismaGenerated_DO_NOT_TOUCH";

/**
 * This file solves two issues:
 * 1. Auto-importing types from prisma doesn't work on our version of TS, and possibly not at all
 *    on any version. https://github.com/prisma/prisma/issues/14392
 * 2. Sometimes the schema version of the types is different from our manually-maintained version
 *    of the types. Adding "*Prisma" suffix helps disambiguate them until we can align better.
 */

export type GatherEventPrisma = GatherEvent;
export type DiscountPrisma = Discount;
export type SpaceCapacityWarningPrisma = SpaceCapacityWarning;
export type SpaceCapacityWarningUserPrisma = SpaceCapacityWarningUser;
export type SpaceExtensionPrisma = SpaceExtension;
export type ReservationPrisma = Reservation;
export type ReservationCreateInput = Prisma.ReservationCreateInput;
export type ReservationUpdateInput = Prisma.ReservationUpdateInput;
export type WearablePrisma = Wearable;
export type WearablePartPrisma = WearablePart;
export type WearableCreateInput = Prisma.WearableCreateInput;
export type WearablePartCreateInput = Prisma.WearablePartCreateInput;
export type WearableWithPartsPrisma = Prisma.WearableGetPayload<{ include: { parts: true } }>;
export type WearableCreateInputWithWearablePartsCreateInputSimplified = WearableCreateInput & {
  parts: Prisma.WearablePartCreateWithoutWearableInput[];
};
export type ExternalIntegrationPrisma = ExternalIntegration;
export type GatherEventMessagePrisma = GatherEventMessage;
export type SpawnTokenPrisma = SpawnToken;
export type SpaceItemPrisma = SpaceItem;
export type ObjectTemplatePrisma = ObjectTemplate;
export type ObjectVariantPrisma = ObjectVariant;
export type SoundPrisma = Sound;
export type FilePrisma = File;
export type SpaceTrialPrisma = SpaceTrial;
export type SpaceTrialUpdateInput = Prisma.SpaceTrialUpdateInput;
export type SpaceTrialCreateInput = Prisma.SpaceTrialCreateInput;
export type SpaceStripeCustomerPrisma = SpaceStripeCustomer;
export type RecordingPrisma = Recording;
export type RecordingCreateInput = Prisma.RecordingCreateInput;
export type SpaceUserOnboardingPrisma = SpaceUserOnboarding;
export type SpaceUserOnboardingChecklistPrisma = SpaceUserOnboardingChecklist;
export type UserPrisma = User;
export type UserOutfitPrisma = UserOutfit;
export type UserGatherEventPrisma = UserGatherEvent;
export type UserSpeakerPrisma = UserSpeaker;
export type UserDeletionForStagingPrisma = UserDeletionForStaging;
export type SpaceUserPrisma = SpaceUser;
export type SpaceUserDeskPrisma = SpaceUserDesk;
export type SpaceUserSettingPrisma = SpaceUserSetting;
export type SpaceUserCreateInput =
  | Prisma.SpaceUserCreateInput
  | Prisma.SpaceUserUncheckedCreateInput;
export type SpaceUserUpdateInput = Prisma.SpaceUserUpdateInput;
export type SpacePrisma = Space;

/**
 * Exported enums
 * ===============
 *
 * You need to export as a const, otherwise you can't access it as a value.
 */

export {
  Prisma,
  GatherEventCategory,
  GatherEventAttendanceNumber as GatherEventAttendanceNumberPrisma,
  GatherEventPrivacy,
  GatherEventProfessionalCategory,
  GatherEventUseCase,
  DiscountType,
  ExtensionType,
  ReservationType as ReservationTypePrisma,
  ReservationPlan as ReservationPlanPrisma,
  ReservationPaymentFailureContext as ReservationPaymentFailureContextPrisma,
  ExternalIntegrationType,
  WearableLayer,
  WearableType as WearableTypePrisma,
  WearableSubType as WearableSubTypePrisma,
  SpaceTemplateTab,
  SpaceTemplateSubtab,
  SpaceTemplateSize,
  SpaceTemplateArea,
  GatherEventMessageRecipient,
  SpaceTrialSource,
  SpaceRole as SpaceRolePrisma,
  SpaceUserStatus,
  SpaceUserStatusEnd,
  SurveyResponseType,
  Permission,
  SpaceSSOConnectionState,
  DeviceType,
  MemberOnboardingDesk,
  MemberOnboardingStep,
  SpaceSSOEmailDomainVerificationState,
  TutorialTask as TutorialTaskPrisma,
  AdminRoleType as AdminRoleTypePrisma,
  GuestPassStatus as GuestPassStatusPrisma,
  SpaceUserStatusEnd as SpaceUserStatusEndPrisma,
  UserOutfitTemplateType,
  PricingServiceSubscriptionBillingInterval,
  PricingServiceSubscriptionCollectionMethod,
  PricingServiceSubscriptionStatus,
  PricingPlan,
  UseCase,
  SpaceChat,
  SpaceUserRequestStatus,
  SpaceUserRequestStatusChangeSource,
} from "./prismaGenerated_DO_NOT_TOUCH";

export type {
  ApiKey as ApiKeyPrisma,
  LegacyInviteToken as LegacyInviteTokenPrisma,
  InviteToken as InviteTokenPrisma,
  ObjectTemplateCategory as ObjectTemplateCategoryPrisma,
  GoogleToken as GoogleTokenPrisma,
  BrowserVideoClientStateSession,
  UserFeedback,
  SurveyResponse,
  GatherEventMessageReply as GatherEventMessageReplyPrisma,
  GatherEventMessageLikedUser,
  SpaceRolePermissionOverride,
  SpaceWorkOSConnection,
  UserDevice,
  SpaceSSOEmailDomain,
  SpaceSSOLinkedSpace,
  PricingServicePaymentMethod,
  PricingServiceCustomer,
  PricingServiceSubscription,
  SpacePlanInterval,
  SpaceUserRequest,
} from "./prismaGenerated_DO_NOT_TOUCH";

// Regrettably we can't model unions of types in Prisma (which makes sense),
// so we write the union here. At some point, we should just finish migrating the legacy
// values and unifying this to a single enum.
export const GatherEventAttendanceNumber = {
  num_0_to_25: "num_0_to_25",
  num_25_to_50: "num_25_to_50",
  num_50_to_100: "num_50_to_100",
  num_100_to_200: "num_100_to_200",
  num_200_orMore: "num_200_orMore",
} as const;

export type GatherEventAttendanceNumber =
  (typeof GatherEventAttendanceNumber)[keyof typeof GatherEventAttendanceNumber];

export const GatherEventAttendanceNumberLegacy = {
  num_0_to_25: "num_0_to_25",
  num_26_to_100Legacy: "num_26_to_100Legacy",
  num_101_orMoreLegacy: "num_101_orMoreLegacy",
} as const;

export type GatherEventAttendanceNumberLegacy =
  (typeof GatherEventAttendanceNumberLegacy)[keyof typeof GatherEventAttendanceNumberLegacy];

export const ReservationType = {
  Classroom: "Classroom",
  Conference: "Conference",
  Party: "Party",
  RemoteWork: "RemoteWork",
  Other: "Other",
} as const;
export type ReservationType = (typeof ReservationType)[keyof typeof ReservationType];

export const ReservationPlan = {
  TwoHour: "TwoHour",
  Daily: "Daily",
  Monthly: "Monthly",
  Annual: "Annual",
} as const;

export type ReservationPlan = (typeof ReservationPlan)[keyof typeof ReservationPlan];

// Subset of BrowserVideoClientStateSession
export type BrowserVideoClientStateSessionIndex = Pick<
  BrowserVideoClientStateSession,
  | "id"
  | "avStrategy"
  | "commitHash"
  | "buildTimestamp"
  | "createdAt"
  | "electron"
  | "message"
  | "playerId"
  | "spaceId"
  | "updatedAt"
  | "userAgentBrowser"
  | "userAgentOs"
  | "userAgentVersion"
  | "sessionId"
  | "liveKitSessionId"
  | "liveKitParticipantId"
  | "agoraVideoId"
  | "agoraScreenId"
  | "agoraVideoChannelName"
  | "agoraScreenChannelName"
>;

// When we export SpawnTokenType from Prisma, we see the following error:
// error TS2702: 'SpawnTokenType' only refers to a type, but is being used as a namespace here
// We should fix this once we figure out how to get rid of this error [OCTO-200]
export enum SpawnTokenType {
  SpawnTile = "SpawnTile",
  Nook = "Nook",
  Desk = "Desk",
  DefaultSpawnTile = "DefaultSpawnTile",
}
export type SpaceUserSettingPinnedUser = {
  pinnedUserId: string;
};

/**
 * We also need to re-export the raw model types, otherwise we run into infamous "inferred type
 * cannot be named" TS errors in some places:
 * https://gather-town.slack.com/archives/C03CQTHPKH8/p1659541105444499
 * If you get this error, import the type below, not the one above, and rename as `MyModelPrisma`.
 */
export type {
  GatherEvent,
  Discount,
  Reservation,
  Wearable,
  WearablePart,
  SpaceTemplate,
  ExternalIntegration,
  SpaceItem,
  SpawnToken,
  ObjectTemplate,
  ObjectVariant,
  ObjectVariantSpritesheet,
  ObjectVariantSpritesheetAnimation,
  GatherEventMessage,
  SpaceSSOSettings,
  UserOutfitTemplate,
};
