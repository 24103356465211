const QueryKeys = {
  AdminUsers: "adminUsers",
  Discounts: "discounts",
  ObjectTemplates: "objectTemplates",
  ObjectTemplate: "objectTemplate",
  ObjectTemplateCategories: "objectTemplateCategories",
  Reservation: "reservation",
  Space: "space",
  SpaceMaps: "spaceMaps",
  SpaceMap: "spaceMap",
  SpaceReservations: "spaceReservations",
  SpaceUsers: "spaceUsers",
  User: "user",
  UserDevices: "userDevices",
  UserReservations: "userReservations",
  UserSpaces: "userSpaces",
  Wearables: "wearables",
};

export default QueryKeys;
